import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./UnprocessedWellnessVisits.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import get from "lodash/get";
import map from "lodash/map";
import values from "lodash/values";
import * as WellnessActions from "actions/WellnessActions";
import AccessDenied from "components/common/AccessDenied";
import ApprovalButtons from "components/common/ApprovalButtons";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import WellnessVisitAccordionList from "components/wellness/widgets/WellnessVisitAccordionList";
import { getClinicInfo } from "utils/ClinicData";
import * as ClinicActions from "actions/ClinicActions";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function UnprocessedWellnessVisits(props) {
    const [approveInitials, setApproveInitials] = useState(null);
    const [dvmId, setDvmId] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const fetchData = () => {
        if (props.canViewClinicWellnessAudit) {
            props.getUnprocessedWellnessVisits(props.clinicId);
        }
        if (props.canViewClinicWellnessDvms) {
            props.loadClinicDvms(props.clinicId);
        }
        if(props.canViewClinicWellnessAudit) {
            props.getProviderLocations(props.clinicId);
        }
    }

    useEffect(() => {
        fetchData();
    }, [props.clinicId]);

    useEffect(() => {
        handleRowSelectChanged(props.selectedRows);
    }, [props.selectedRows]);

    const handleRowSelectChanged = (rowIds) => {
        setSelectedRows(rowIds);
    }

    const clearSelectedVisits = () => {
        setSelectedRows([]);
    }

    const handleApproveVisitsClicked = (initials) => {
        if (props.canApproveClinicWellnessAudit) {
            props.processWellnessVisits(selectedRows, initials);
            clearSelectedVisits();
        }
    }

    const handleDVMChanged = ({ selection, row }) => {
        if (props.canChangeDvmClinicWellnessAudit) {
            const relatedVisits = filter(props.visits, v => ((v.invoiceId === row.invoiceId) && !v.dvmId));
            if (relatedVisits.length > 1) {
                setDvmId(selection);
                setSelectedRow(row);
            } else {
                props.updateWellnessVisits(selection.value, row.visitId);
            }
        }
    }

    const handleBulkChangeDvm = () => {
        if (props.canChangeDvmClinicWellnessAudit) {
            props.updateWellnessVisits(dvmId, [...selectedRows]);
            setDvmId(null);
            setApproveInitials("");
            setSelectedRows([]);
        }
    };

    const updateApproveInitials = ({ name, value }) => {
        setApproveInitials(value);
    };

    // Dirt simple text filtering
    const filterByText = (visits) => {
        if (!props.search) {
            return visits;
        }
        const text = props.search.toLowerCase();

        let filtered = [...visits];

        filtered = filtered.filter(visit => {
            return visit.extendedInvoiceNumber.toString().includes(text) ||
                (visit.dvm && visit.dvm.toLowerCase().includes(text)) ||
                visit.client.toLowerCase().includes(text) ||
                visit.pet.toLowerCase().includes(text) ||
                visit.productName.toLowerCase().includes(text)
        });

        return filtered;
    }

    const getFilteredList = () => {
        let filtered = filter(values(props.visits), v => v.isVisitCurrent);

        if (filtered && filtered.length > 0) {
            // Time filter handled by API call
            if (props.search) {
                filtered = filterByText(filtered);
            }
        }

        return filtered;
    }

    const renderVisitList = (filteredList) => {
        return (
            <WellnessVisitAccordionList
                visits={filteredList}
                clinicId={props.clinicId}
                providerList={props.providers}
                wellnessProviders={props.wellnessProviders}
                // onSelect={handleSelectionChanged}
                onDVMChanged={handleDVMChanged}
                onRowSelectChange={handleRowSelectChanged}
                selectedRows={selectedRows}
            />
        );
    }

    const closeRelated = () => {
        setDvmId(null);
        setSelectedRow(null);
    }

    const changeSingleDVM = (visitId, dvmId) => {
        if (dvmId &&  visitId) {
            props.updateWellnessVisits(dvmId, visitId);
            closeRelated();
        }
    };

    const changeAllDVMs = (relatedVisits=[], dvmId) => {
        if (dvmId &&  relatedVisits.length) {
            forEach(relatedVisits, visit => {
                props.updateWellnessVisits(dvmId, visit.visitId);
            });
            closeRelated();
        }
    };

    const renderAddDvmToRelated = () => {
        const selectedDvm = find(props.dvms, { clinicDvmId: dvmId.value });
        const relatedVisits = filter(props.visits, v => (v.invoiceId === selectedRow?.invoiceId && !v.dvmId));
        return (
            <div>
                <div className="text-lg">
                    Would you like to Assign <b>{selectedDvm.firstName} {selectedDvm.lastName}</b> as the D.V.M for all <b>({relatedVisits?.length})</b> unassigned line items from invoice number <b>{selectedRow?.extendedInvoiceNumber}</b>?
                </div>
                <div className="margin-top-md">
                    {/*<div className="margin-bottom-sm text-lg">*/}
                    {/*    <CheckboxInput*/}
                    {/*        name="dontAskAgainToday"*/}
                    {/*        label="Don't ask again Today"*/}
                    {/*        value={}*/}
                    {/*        onChange={}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="flex-1 flex justify-flex-end spaced-content">
                        <Button
                            onClick={() => changeSingleDVM(selectedRow?.visitId, dvmId.value)}
                        >
                            Assign Only One
                        </Button>
                        <Button
                            onClick={() => changeAllDVMs(relatedVisits, dvmId.value)}
                        >
                            Assign All
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    if (!props.visits.length && !props.isWellnessLoading) {
        return (
            <div className={styles.noVisits}>
                <span>Good Work! You've processed all your visits!</span>
            </div>
        )
    }

    const filteredList = getFilteredList();

    if (filteredList.length === 0 && !props.isWellnessLoading) {
        return (
            <div className={styles.noVisits}>
                <span>No search results. Try changing your search terms.</span>
            </div>
        )
    }

    if (!props.canViewClinicWellnessAudit) {
        return <AccessDenied/>;
    }

    const dvmOptions = map(props.dvms, dvm => ({ name: `${dvm.firstName} ${dvm.lastName} (${dvm.pimsUserRemoteDisplayId})`, value: dvm.id }));

    return (
        <>
            <div className={styles.root}>
                {renderVisitList(filteredList)}
                {props.canApproveClinicWellnessAudit && (
                    <div className="flex">
                        <div className="flex-1 margin-left-x-sm">
                            <ApprovalButtons
                                onApprove={handleApproveVisitsClicked}
                                // onDecline={handleDeclineVisitsClicked}
                                locked={!selectedRows.length}
                                onClose={props.onClose}
                                cannotDecline
                                showApproveByDefault={props.showApproveByDefault}
                                useFakeInitials={props.showApproveByDefault}
                            />
                        </div>
                    </div>
                )}
                {props.isAdminView && (
                    <div className="flex">
                        <div className="flex align-center" style={{ borderTop: "1px solid #cfcfcf", paddingTop: "1em"}}>
                            Change DVM for all selected items:
                            <div className="margin-left-sm margin-right-md" style={{ maxWidth: "40%" }}>
                                <Dropdown
                                    name="dvmId"
                                    value={dvmId}
                                    options={dvmOptions}
                                    onChange={selection => setDvmId(selection.value)}
                                    placeholder="Select D.V.M."
                                    // disabled={row.isPlanLocked || !row.isVisitCurrent}
                                    dropdownDirection="up"
                                />
                            </div>
                            <Button
                                onClick={handleBulkChangeDvm}
                                disabled={!dvmId || !approveInitials || approveInitials.length < 2}
                            >
                                Apply DVM
                            </Button>
                        </div>
                        <div className="flex-1 margin-right-sm">
                            <div className="flex align-center margin-top-sm">
                                By entering my initials I certify that the foregoing is true and correct.
                                <div className="margin-left-sm" style={{ maxWidth: "30%" }}>
                                    <TextBox
                                        placeholder="Enter initials"
                                        name="approveInitials"
                                        value={approveInitials}
                                        onChange={updateApproveInitials}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal
                show={!!(selectedRow && dvmId)}
                modalTitle="Assign DVM to All Related Items"
                small
                onClose={closeRelated}
            >

                {!!(selectedRow && dvmId) && renderAddDvmToRelated()}
            </Modal>
            <SpinnerTakeover show={props.isWellnessUpdating || props.isWellnessLoading || props.isDVMsLoading} />
        </>
    );
}

UnprocessedWellnessVisits.defaultProps = {
    isAdminView: false,
    canGLWellness: false,
    selectedRows: [],
    showApproveByDefault: false,
};

UnprocessedWellnessVisits.propTypes = {
    onClose: PropTypes.func.isRequired,
    clinicId: PropTypes.number.isRequired,
    isAdminView: PropTypes.bool,
    canGLWellness: PropTypes.bool,

    // For the Tour
    selectedRows: PropTypes.array,
    showApproveByDefault: PropTypes.bool,
};


export default connect(
    (state, ownProps) => {
        const clinicId = !ownProps.clinicId || isNaN(ownProps.clinicId) ? get(getClinicInfo(state, null), "id") : ownProps.clinicId;  // clean up clinicId
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canApproveClinicWellnessAudit = userHasPermission(PermissionTypes.APPROVE, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canChangeDvmClinicWellnessAudit = userHasPermission(PermissionTypes.CHANGE_DVM, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);

        return {
            canViewClinicWellnessAudit,
            canApproveClinicWellnessAudit,
            canChangeDvmClinicWellnessAudit,
            canViewClinicWellnessDvms,
            clinicId,
            visits: values(state.entities.wellness?.visits || {}),
            providers: state.entities.clinicWellnessProviders,
            search: state.entities.wellness.query,
            isWellnessUpdating: state.entities.wellness.isWellnessUpdating || false,
            isWellnessLoading: state.entities.wellness.isWellnessLoading || false,
            isDVMsLoading: state.entities.dvms.loading || false,
            dvms: filter(state.entities.dvms, dvm => (dvm.clinicId === clinicId && dvm.isActive)),
        }
    },
    (dispatch) => ({
        getUnprocessedWellnessVisits: (clinicId) => dispatch(WellnessActions.getUnprocessedWellnessVisits(clinicId)),
        processWellnessVisits: (visits, initials) => dispatch(WellnessActions.processWellnessVisits(visits, initials)),
        updateWellnessVisits: (dvmId, visits) => dispatch(WellnessActions.updateWellnessVisits(dvmId, visits)),
        loadClinicDvms: (clinicId) => dispatch(WellnessActions.getDvmsForClinic(clinicId)),
        getProviderLocations: (clinicId) => dispatch(ClinicActions.getProviderLocations(clinicId, true)),
    }),
)(UnprocessedWellnessVisits);
